import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vimagezoom',
  templateUrl: './vimagezoom.component.html',
  styleUrls: ['./vimagezoom.component.scss'],
})
export class VimagezoomComponent implements OnInit {
  @Input() photo: any;
  @Input() image: any;
  
  constructor() { }

  ngOnInit() {}

}
