import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VimagezoomComponent } from './chat/vimagezoom/vimagezoom.component';
import { environment } from 'src/environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GlobalService } from './services/global.service';
import { HttpClientModule } from '@angular/common/http';
import { WINDOW_PROVIDERS } from './services/window-ref.service';
import { MatDialogModule } from '@angular/material/dialog';
import { ThemeComponent } from './chat/theme/theme.component';
import { SortPipe } from './pipes/sort.pipe';
import { SearchPipe } from './pipes/search.pipe';

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { AnalyticsService } from './services/analytics.service';
import { UserService } from './services/user.service';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';

export const app = initializeApp(environment.firebase);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const messaging = getMessaging(app);

@NgModule({
  declarations: [
    AppComponent,
    ThemeComponent,
    SortPipe,
    SearchPipe,
    VimagezoomComponent
  ],
  entryComponents: [

  ],
  imports: [
    BrowserModule, 
    HttpClientModule,
    MatDialogModule,
    IonicModule.forRoot(), 
    AppRoutingModule, 
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GlobalService,
    AnalyticsService,
    UserService,
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

