import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent implements OnInit {
  @Input() theme;
  @Output() picked: EventEmitter<any> = new EventEmitter();
  libcodeselect: any;
  libeditselect: any;

  constructor() { }

  ngOnInit() {

  }

  displayLiblary(selected) {
    if(this.libcodeselect === selected) {
      this.libcodeselect = null;
    } else {
      this.libcodeselect = selected;
    }
    console.log(this.libcodeselect, selected)
  }

  editLiblary(selected) {
    if(this.libeditselect === selected) {
      this.libeditselect = null;
    } else {
      this.libeditselect = selected;
    }
    console.log(this.libeditselect, selected)
  }

  updateStyle(data, index) {
    if (data) {
      this.theme[index].css = data;
      console.log(this.theme)
      this.picked.emit(this.getStyleObj());
      console.log(this.getStyleObj())
    }
  }

  getStyleObj() {
    let styleObj = {}
    this.theme.forEach(style => {
      if(!style.css) return
      styleObj[style.name] = style.css
    })
    return styleObj
  }

  handlePick(style) {
    console.log('picked ::', style)
    this.theme.forEach((element, index) => {
      if(element.name === this.libeditselect) {
        this.updateStyle(style, index)
        this.editLiblary(this.libeditselect)
      }
    });
  }

}
