import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { getAuth, User } from 'firebase/auth';
import { collection, getDocs, getFirestore } from 'firebase/firestore/lite';
import { auth, firestore } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit {
  user: User;
  loading: boolean;
  userSub: EventEmitter<User> = new EventEmitter();

  constructor(
    ) {
      auth.onAuthStateChanged((userState) => {
        this.user = userState
        this.userSub.next(this.user);
      })
   }

   async ngOnInit() {
    this.loading = true;
    this.user = await auth.currentUser;
    this.userSub.next(this.user);
   }

   public async getUser(): Promise <User> {
    return auth.currentUser
   }

   public async getUserCollection(collectionName: string) {
     return getDocs(collection(firestore, `users/${this.user.uid}/${collectionName}`));
   }

   getUserState() {
     return Promise.resolve(auth.currentUser)
    .then((user: User) => {
      this.user = user
       return user
    })
  }

   async getUserInfo(request) {
    this.user = await this.getUserState();
    if(!this.user) return 'nouser';
    switch (request) {
      case 'user':
        return this.user
        break
      case 'img':
        return this.user.photoURL
        break
      case 'uid':
        return this.user.uid
        break
      case 'name':
        return this.user.displayName
        break
      case 'email':
        return this.user.email
        break
      case 'phone':
        return this.user.phoneNumber
        break
    }
  }
}

function Output() {
  throw new Error('Function not implemented.');
}

