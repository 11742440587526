import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { analytics } from '../app.module';

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  constructor(public snackBar: MatSnackBar) { }

  logEvent(eventName: string, eventParams?: {[key: string]: any;}) {
    if(environment.production){
      logEvent(analytics, eventName, eventParams)
    } else {
      return 
    }
  }

  warnmessage(string) {
    return this.snackBar.open(string, 'Ok!', {
      duration: 3000,
      panelClass: 'warn'
    });
  }

  usermessage(string) {
    return this.snackBar.open(string, 'Ok!', {
      duration: 3000,
    });
  }

}
